import React, { useState } from 'react';
import './Developers.css'; // Import the Developers page styles
import developers from '../../../Assets/developers'; // Import the data from the assets folder

const Developer = () => {
  return (
    <div className="Dev-content">
      <ul className="team">
        {developers.map((member, index) => (
          <li key={index} className={`member ${member.name !== 'Isaac Abakah' ? 'co-funder' : ''}`}>
            <div className="thumb">
              <img src={member.image} alt={member.name} />
            </div>
            <div className="description">
              <h3>{member.name}</h3>
              <DescriptionWithReadMore description={member.description} githubLink={member.link} role={member.role} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const DescriptionWithReadMore = ({ description, githubLink, role }) => {
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div>
      <p>
        {isReadMore ? description : `${description.substring(0, 100)}... `}
        <span onClick={toggleReadMore} className="read-more-btn">
          {isReadMore ? 'Show less' : 'Read more'}
        </span>
      </p>
      {/* Display the role */}
      <p className="role">{role}</p>
      <br/>
      {/* GitHub link now appears below the role */}
      <a
        href={`https://github.com/${githubLink}`}
        target="_blank"
        rel="noopener noreferrer"
        className="github-link"
      >
        @{githubLink}
      </a>
    </div>
  );
};

export default Developer;
