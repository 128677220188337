import React from 'react';
import '../OrganicStyles/OrganicStyles.css'


const AlkanoicAcids = () => {
  return (
    <div className="organic-page">
      <div className="organic-head">
        <h1>ALKANOIC ACIDS</h1>
        <hr />
        <p>
          Alkanoic acids, also known as carboxylic acids, are organic compounds containing a carboxyl group (-COOH). They are weak acids that play important roles in both biochemistry and industrial processes.
        </p>
      </div>
      <div className="organic-content">
        <h2>Overview of Alkanoic Acids</h2>
        <p>
          Alkanoic acids are characterized by the presence of a carboxyl group (-COOH) attached to an alkyl chain. Their general formula is C<sub>n</sub>H<sub>2n+1</sub>COOH, where n is the number of carbon atoms.
        </p>

        <h2>Properties of Alkanoic Acids</h2>
        <p><b>Acidity:</b> Alkanoic acids are weak acids that partially dissociate in water, releasing protons (H<sup>+</sup>).</p>
        <p><b>Boiling Points:</b> Alkanoic acids have higher boiling points due to hydrogen bonding between carboxyl groups.</p>
        <p><b>Solubility:</b> Lower alkanoic acids are soluble in water, but solubility decreases as the carbon chain length increases.</p>

        <h2>Chemical Reactions of Alkanoic Acids</h2>
        <p><b>Esterification:</b> Alkanoic acids react with alcohols to form esters and water.</p>
        <p><b>Reduction:</b> Alkanoic acids can be reduced to aldehydes and alcohols using reducing agents.</p>
        <p><b>Neutralization:</b> Alkanoic acids react with bases to form salts and water.</p>

        <h2>Uses of Alkanoic Acids</h2>
        <p><b>Preservatives:</b> Alkanoic acids like acetic acid are used as preservatives in the food industry.</p>
        <p><b>Pharmaceuticals:</b> Alkanoic acids are used in the production of drugs, such as aspirin.</p>
        <p><b>Surfactants:</b> Alkanoic acids are used in the manufacture of soaps and detergents.</p>

        <h2>Characteristics of Alkanoic Acids</h2>
        <p>Alkanoic acids contain a carboxyl group (-COOH), which is responsible for their acidic properties and reactivity in esterification and neutralization reactions.</p>

        <h2>Bond Formation in Alkanoic Acids</h2>
        <p>In alkanoic acids, the carboxyl group (-COOH) consists of a carbonyl group (C=O) and a hydroxyl group (-OH), both of which contribute to hydrogen bonding and the acid’s physical properties.</p>
      </div>
    </div>
  );
};

export default AlkanoicAcids;
