import React from 'react';
import './Iupac.css'; // Import your CSS for styling
import pdfs from '../../../Assets/pdfs'; // Assume this is an array of PDF data, with name and src properties

const Iupac = () => {
  // Find the specific PDF for IUPAC Nomenclature Rules
  const iupacPdf = pdfs.find(pdf => pdf.name === 'IUPAC Nomenclature Rules');

  return (
    <div className="iupac-container">
      <h1>IUPAC Nomenclature Rules</h1>
      <p>View PDF of the IUPAC Nomenclature Rules</p>

      <div className="pdf-section">
        
        
        {/* Embed the IUPAC PDF */}
        {iupacPdf ? (
          <iframe 
            src={iupacPdf.src} // Update this line to use iupacPdf.src
            title="IUPAC Nomenclature PDF" 
            width="100%" 
            height="600px" 
          ></iframe>
        ) : (
          <p>PDF not available</p>
        )}
      </div>
    </div>
  );
}

export default Iupac;