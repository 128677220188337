import React, { useState, useEffect, useRef } from 'react';
import './NavBar.css';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const NavBar = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const navRef = useRef(null);

  const handleLinkClick = (path) => {
    setIsMobileNavOpen(false);
    setIsDropdownOpen(false);
    navigate(path);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMobileNavOpen(false);
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar" ref={navRef}>
      <div className="navbar-logo">
        Chem <i className="fas fa-flask flask-icon"></i>
        <span>Lab</span>
      </div>
      
      <button
        className={`hamburger ${isMobileNavOpen ? 'open' : ''}`}
        onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
        aria-label="Toggle menu"
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>

      <ul className={`nav-items ${isMobileNavOpen ? 'mobile-nav active' : 'desktop-nav'}`}>
        <li className="nav-item fade-in">
          <button onClick={() => handleLinkClick("/")}>
            <i className="fas fa-home"></i> Home
          </button>
        </li>
        <li className="nav-item fade-in">
          <button onClick={() => handleLinkClick("/iupac")}>
            <i className="fas fa-book"></i> IUPAC Rules
          </button>
        </li>
        <li className={`nav-item dropdown fade-in ${isDropdownOpen ? 'active' : ''}`}>
          <button className="dropdown-toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <i className="fas fa-flask"></i> Organic CPDs 
            <i className={`fas ${isDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
          </button>
          <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
            <li><button onClick={() => handleLinkClick("/alkanes")}>Alkanes</button></li>
            <li><button onClick={() => handleLinkClick("/alkenes")}>Alkenes</button></li>
            <li><button onClick={() => handleLinkClick("/alkanols")}>Alkanols</button></li>
            <li><button onClick={() => handleLinkClick("/alkynes")}>Alkynes</button></li>
            <li><button onClick={() => handleLinkClick("/alkanoic-acids")}>Alkanoic Acids</button></li>
            <li>
              <button onClick={() => handleLinkClick("/access")}>
                <i className="fas fa-file-pdf"></i> Access PDFs
              </button>
            </li>
          </ul>
        </li>
        <li className="nav-item fade-in">
          <button onClick={() => handleLinkClick("/developers")}>
            <i className="fas fa-code"></i> Developers
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;