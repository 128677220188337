const _0x3c4d =
  'prefices,meth,eth,prop,but,pent,hex,hept,oct,non,dec,undec,dodec,tridec,tetradec,pentadec,hexadec,heptadec,octadec,nonadec,icos,heneicos,docos,tricos,tetracos,pentacos,hexacos,heptacos,octacos,nonacos,triacont,hentriacont,dotriacont,tritriacont,tetratriacont,pentatriacont,hexatriacont,heptatriacont,octatriacont,nonatriacont,tetracont,hentetracont,dotetracont,tritetracont,tetratetracont,pentatetracont,hexatetracont,heptatetracont,octatetracont,nonatetracont,pentacont,henpentacont,dopentacont,tripentacont,tetrapentacont,pentapentacont,hexapentacont,heptapentacont,octapentacont,nonapentacont,hexacont,henhexacont,dohexacont,trihexacont,tetrahexacont,pentahexacont,hexahexacont,heptahexacont,octahexacont,nonahexacont,heptacont,henheptacont,doheptacont,triheptacont,tetraheptacont,pentaheptacont,hexaheptacont,heptaheptacont,octaheptacont,nonaheptacont,octacont,henoctacont,dooctacont,trioctacont,tetraoctacont,pentaoctacont,hexaoctacont,heptaoctacont,octaoctacont,nonaoctacont,nonacont,hennonacont,dononacont,trinonacont,tetranonacont,pentanonacont,hexanonacont,heptanonacont,octanonacont,nonanonacont,hect,henihect,dohect,trihect,tetrahect,pentahect,hexahect,heptahect,octahect,nonahect,decahect,undecahect,dodecahect,tridecahect,tetradecahect,pentadecahect,hexadecahect,heptadecahect,octadecahect,nonadecahect,icosahect';
const _0x5e6f = _0x3c4d.split(',');
class _0x1a2b {
  constructor(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n) {
    this._0x7g8h = _0x7g8h;
    this._0x9i0j = _0x9i0j;
    this._0x1k2l = _0x1k2l;
    this._0x3m4n = _0x3m4n;
  }
}
export default function _0x5o6p(_0x7q8r) {
  const _0x7p8r = _ox1u2v(_0x7q8r);
  let _0x9s0t = _ox3w4x(_0x7p8r);
  if (!_0x9s0t) {
    return _0x5y6z(_0x7p8r);
  } else {
    return 'Invalid character in formula!';
  }
}
function _0x7a1b(_0x7q8r) {
  let _0x7g8h = 'Unavailable';
  let _0x9i0j = 0;
  let _0x3m4n = '';
  let _0x1k2l = false;
  const _0x9c2d = /^C(\d*)H(\d+)$/;
  const _0x3e4f = _0x7q8r.match(_0x9c2d);
  if (_0x3e4f) {
    _0x9i0j = _0x5g6h(_0x3e4f[1]);
    let _0x7i8j = _0x5g6h(_0x3e4f[2]);
    if (_0x7i8j === 2 * _0x9i0j + 2) {
      _0x7g8h = 'alkane';
    } else if (_0x9i0j > 1 && _0x7i8j === 2 * _0x9i0j) {
      _0x7g8h = 'alkene';
    } else if (_0x9i0j > 1 && _0x7i8j === 2 * _0x9i0j - 2) {
      _0x7g8h = 'alkyne';
    }
    return new _0x1a2b(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n);
  }
  const _0x9k0l = /^C(\d*)H(\d+)O(\d*)$/;
  const _0x1m2n = _0x7q8r.match(_0x9k0l);
  if (_0x1m2n) {
    _0x9i0j = _0x5g6h(_0x1m2n[1]);
    let _0x7i8j = _0x5g6h(_0x1m2n[2]);
    let _0x3o4p = _0x5g6h(_0x1m2n[3]);
    if (_0x7i8j === 2 * _0x9i0j + 2 && _0x3o4p === 1) {
      _0x7g8h = 'alkanol';
    } else if (_0x7i8j === 2 * _0x9i0j && _0x3o4p === 2) {
      _0x7g8h = 'alkanoic acid';
    }
    return new _0x1a2b(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n);
  }
  const _0x5q6r = /^CH3-?(CH2-?)*CH3$/;
  const _0x7s8t = _0x7q8r.match(_0x5q6r);
  if (_0x7s8t) {
    _0x9i0j = _0x9u0v(_0x7q8r);
    _0x7g8h = 'alkane';
    if (_0x9i0j > 3) {
      _0x3m4n = 'n';
      _0x1k2l = true;
    }
    return new _0x1a2b(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n);
  }
  const _0x1w2x =
    /^(CH2=?CH2|CH2=?CH-?(CH2-?)*CH3|CH3-?(CH2-?)*CH=?CH2|CH3-?(CH2-?)*CH=?CH-?(CH2-?)*CH3)$/;
  const _0x3y4z = _0x7q8r.match(_0x1w2x);
  if (_0x3y4z) {
    _0x9i0j = _0x9u0v(_0x7q8r);
    _0x7g8h = 'alkene';
    if (_0x9i0j > 3) {
      _0x3m4n = _0x5a1b(_0x7q8r, _0x7g8h, _0x9i0j);
      _0x1k2l = true;
    }
    return new _0x1a2b(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n);
  }
  const _0x7c2d =
    /^(CH≡?CH|CH≡?C-?(CH2-?)*CH3|CH3-?(CH2-?)*C≡?CH|CH3-?(CH2-?)*C≡?C-?(CH2-?)*CH3)$/;
  const _0x9e3f = _0x7q8r.match(_0x7c2d);
  if (_0x9e3f) {
    _0x9i0j = _0x9u0v(_0x7q8r);
    _0x7g8h = 'alkyne';
    if (_0x9i0j > 3) {
      _0x3m4n = _0x5a1b(_0x7q8r, _0x7g8h, _0x9i0j);
      _0x1k2l = true;
    }
    return new _0x1a2b(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n);
  }
  const _0x1g4h = /^C(\d*)H(\d+)OH$/;
  const _0x3i5j = /^CH3-?(CH2-?)*OH$/;
  const _0x6k7l = _0x7q8r.match(_0x1g4h);
  const _0x8m9n = _0x7q8r.match(_0x3i5j);
  if (_0x6k7l || _0x8m9n) {
    let _0x7i8j = 0;
    if (_0x6k7l) {
      _0x9i0j = _0x5g6h(_0x6k7l[1]);
      _0x7i8j = _0x5g6h(_0x6k7l[2]);
    } else if (_0x8m9n) {
      _0x9i0j = _0x9u0v(_0x7q8r);
      _0x7i8j = 2 * _0x9i0j + 1;
    }
    if (_0x7i8j === 2 * _0x9i0j + 1) {
      _0x7g8h = 'alkanol';
    }
    if (_0x9i0j > 2) {
      _0x3m4n = '1';
      _0x1k2l = true;
    }
    return new _0x1a2b(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n);
  }
  if ((_0x7q8r === 'HCOOH') | (_0x7q8r === 'HCO2H')) {
    _0x7g8h = 'alkanoic acid';
    _0x9i0j = 1;
    return new _0x1a2b(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n);
  }
  const _0x2o3p = /^C(\d*)?H(\d+)(COOH|CO2H)$/;
  const _0x9u1v = /^CH3-?(CH2-?)*(COOH|CO2H)$/;
  const _0x2w3x = _0x7q8r.match(_0x2o3p);
  const _0x4y5z = _0x7q8r.match(_0x9u1v);
  if (_0x2w3x || _0x4y5z) {
    let _0x7i8j = 0;
    if (_0x2w3x) {
      _0x9i0j = _0x5g6h(_0x2w3x[1]);
      _0x7i8j = _0x5g6h(_0x2w3x[2]);
      if (_0x7i8j === 2 * _0x9i0j + 1) {
        _0x7g8h = 'alkanoic acid';
        _0x9i0j++;
      }
    } else if (_0x4y5z) {
      _0x9i0j = _0x9u0v(_0x7q8r);
      _0x7g8h = 'alkanoic acid';
    }
    return new _0x1a2b(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n);
  }
  return new _0x1a2b(_0x7g8h, _0x9i0j, _0x1k2l, _0x3m4n);
}
function _0x5y6z(_0x7q8r) {
  let _0x6a7b = 'Invalid structure!';
  const _0x8c9d = _0x7a1b(_0x7q8r);
  if (_0x8c9d._0x7g8h === 'alkane') {
    _0x6a7b = _0x5e6f[_0x8c9d._0x9i0j] + 'ane';
  } else if (_0x8c9d._0x7g8h === 'alkene') {
    _0x6a7b = _0x5e6f[_0x8c9d._0x9i0j] + 'ene';
  } else if (_0x8c9d._0x7g8h === 'alkyne') {
    _0x6a7b = _0x5e6f[_0x8c9d._0x9i0j] + 'yne';
  } else if (_0x8c9d._0x7g8h === 'alkanol') {
    _0x6a7b = _0x5e6f[_0x8c9d._0x9i0j] + 'anol';
  } else if (_0x8c9d._0x7g8h === 'alkanoic acid') {
    _0x6a7b = _0x5e6f[_0x8c9d._0x9i0j] + 'anoic acid';
  }
  if (_0x8c9d._0x1k2l) {
    _0x6a7b = _0x8c9d._0x3m4n + '-' + _0x6a7b;
  }
  return _0x6a7b;
}
function _0x9u0v(_0x7q8r) {
  return _0x7q8r.split('').filter((c) => c === 'C').length;
}
function _0x5a1b(_0x7q8r, _0x7g8h, _0x9i0j) {
  let _0x3m4n = 0;
  let _0x0e1f = '';
  if (_0x7g8h === 'alkene') {
    _0x0e1f = /(^CH2=?CH|CH=?CH\D|CH=?CH2$)/;
  } else if (_0x7g8h === 'alkyne') {
    _0x0e1f = /(^CH≡?C|C≡?C[^H]|C≡?CH$)/;
  }
  const _0x3g4h = _0x0e1f.exec(_0x7q8r);
  const _0x5i6j = _0x3g4h.index;
  let _0x7k8l = 0;
  for (let _0x9m0n of _0x7q8r) {
    if (_0x9m0n === 'C' && _0x7k8l <= _0x5i6j) {
      _0x3m4n++;
    }
    _0x7k8l++;
  }
  if (_0x3m4n > _0x9i0j / 2) {
    _0x3m4n = _0x9i0j - _0x3m4n;
  }
  return _0x3m4n.toString();
}
function _ox1u2v(_0x2o3p) {
  return _0x2o3p.replace(/\s/g, '');
}
function _ox3w4x(_0x7q8r) {
  const _0x4q5r = /[^A-Za-z\d*-=≡]/;
  return _0x4q5r.test(_0x7q8r);
}
function _0x5g6h(_0x6s7t) {
  return parseInt(_0x6s7t || '1');
}