import React from 'react';
import '../OrganicStyles/OrganicStyles.css'


const Alkynes = () => {
  return (
    <div className="organic-page">
      <div className="organic-head">
        <h1>ALKYNES</h1>
        <hr />
        <p>
          Alkynes are unsaturated hydrocarbons that contain at least one carbon-carbon triple bond. The simplest alkyne is ethyne, also known as acetylene, with the chemical formula C<sub>2</sub>H<sub>2</sub>.
        </p>
      </div>
      <div className="organic-content">
        <h2>Overview of Alkynes</h2>
        <p>
          Alkynes are hydrocarbons that contain one or more carbon-carbon triple bonds. The general molecular formula for alkynes is C<sub>n</sub>H<sub>2n-2</sub>, where n represents the number of carbon atoms.
        </p>

        <h2>Properties of Alkynes</h2>
        <p><b>State:</b> Alkynes with small carbon chains are gases, while those with longer chains are liquids or solids at room temperature.</p>
        <p><b>Melting and Boiling Points:</b> Alkynes have higher melting and boiling points than alkenes due to their linear structure.</p>
        <p><b>Solubility:</b> Alkynes are insoluble in water but soluble in nonpolar solvents.</p>

        <h2>Chemical Reactions of Alkynes</h2>
        <p><b>Hydrogenation:</b> Alkynes can react with hydrogen to form alkenes and, with excess hydrogen, alkanes.</p>
        <p><b>Halogenation:</b> Alkynes can react with halogens to form tetrahaloalkanes.</p>
        <p><b>Hydration:</b> Alkynes can react with water to form ketones or aldehydes depending on the specific alkyne.</p>

        <h2>Uses of Alkynes</h2>
        <p><b>Welding:</b> Ethyne (acetylene) is used in welding and cutting due to its high heat of combustion.</p>
        <p><b>Organic Synthesis:</b> Alkynes are used as building blocks in the synthesis of various chemicals and pharmaceuticals.</p>

        <h2>Characteristics of Alkynes</h2>
        <p>Alkynes contain a carbon-carbon triple bond, which is shorter and stronger than double bonds in alkenes.</p>
        <p>The carbon atoms in alkynes are sp-hybridized, leading to a linear structure with a bond angle of 180°.</p>

        <h2>Bond Formation in Alkynes</h2>
        <p>In alkynes, each carbon atom in the triple bond forms one sigma bond and two pi bonds, making alkynes highly reactive in addition reactions.</p>
      </div>
    </div>
  );
};

export default Alkynes;
