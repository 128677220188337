import React from 'react';
import '../OrganicStyles/OrganicStyles.css'

const Alkanes = () => {
  return (
    <div className="organic-page">
      <div className="organic-head">
        <h1>ALKANES</h1>
        <hr />
        <p>
          In the early 1800s, French chemist Michel Eugène Chevreul first isolated and named what he called "saturated hydrocarbons." These compounds, now known as alkanes, were found in natural materials like fats and oils. In 1864, German chemist August Wilhelm von Hofmann proposed a systematic naming system for alkanes based on the number of carbon atoms in the chain. This system, with some modifications, is still used today.
        </p>
      </div>
      <div className="organic-content">
        <h2>Overview of Alkanes</h2>
        <p>
          Alkanes are a group of saturated hydrocarbons consisting entirely of single-bonded carbon and hydrogen atoms. They are also known as paraffins. The general molecular formula for alkanes is C<sub>n</sub>H<sub>2n+2</sub>, where n is the number of carbon atoms.
        </p>

        <h2>Properties of Alkanes</h2>
        <p>Alkanes are characterized by the following properties:</p>
        <p><b>State:</b> Alkanes with 1-4 carbon atoms are gases at room temperature, those with 5-17 carbon atoms are liquids, and those with 18 or more carbon atoms are solids.</p>
        <p><b>Melting and Boiling Points:</b> Alkanes have low melting and boiling points compared to other organic compounds due to weak intermolecular forces.</p>
        <p><b>Solubility:</b> Alkanes are nonpolar molecules and are insoluble in water but soluble in nonpolar solvents like benzene and hexane.</p>
        <p><b>Combustibility:</b> Alkanes are highly flammable and undergo combustion reactions with oxygen to produce carbon dioxide and water.</p>

        <h2>Chemical Reactions of Alkanes</h2>
        <p>Alkanes are relatively unreactive due to the strength of the carbon-carbon and carbon-hydrogen single bonds. However, they can undergo the following reactions:</p>
        <p><b>Combustion:</b> Alkanes burn in the presence of oxygen to produce carbon dioxide and water, releasing heat energy.</p>
        <p><b>Halogenation:</b> Alkanes react with halogens like chlorine and bromine in the presence of light to form alkyl halides.</p>
        <p><b>Cracking:</b> Alkanes can be broken down into smaller hydrocarbons through thermal or catalytic cracking processes.</p>

        <h2>Uses of Alkanes</h2>
        <p>Alkanes have various industrial and commercial applications:</p>
        <p><b>Fuel:</b> Alkanes like methane, ethane, and propane are used as fuels for heating, cooking, and transportation.</p>
        <p><b>Raw Materials:</b> Alkanes are used as raw materials in the production of plastics, synthetic rubber, and other chemicals.</p>
        <p><b>Lubricants:</b> Higher alkanes are used as lubricants due to their low reactivity and ability to reduce friction.</p>
        <p><b>Heat Transfer:</b> Alkanes are used in heat transfer fluids for cooling and heating systems.</p>

        <h2>Characteristics of Alkanes</h2>
        <p>Alkanes are characterized by the following features:</p>
        <p>Alkanes consist of carbon (C) and hydrogen (H) atoms with only single bonds between carbon atoms.</p>
        <p>The carbon atoms in alkanes are sp<sup>3</sup> hybridized, resulting in tetrahedral geometry around each carbon atom.</p>
        <p>Alkanes can form straight-chain, branched-chain, or cyclic structures.</p>

        <h2>Bond Formation in Alkanes</h2>
        <p>
          In alkanes, each carbon atom forms four single bonds with other atoms, typically hydrogen atoms. The carbon-carbon and carbon-hydrogen bonds are sigma (σ) bonds formed by the overlap of atomic orbitals.
        </p>
      </div>
    </div>
  );
};

export default Alkanes;
