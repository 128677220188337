import React, { useState } from 'react';
import './Access.css';
import pdfs from '../../../Assets/pdfs'; // Assume this is an array of PDF data, with name and src properties

const Access = () => {
  const [canDownload, setCanDownload] = useState({});

  const handleIframeLoad = (pdfName) => {
    setCanDownload((prevState) => ({
      ...prevState,
      [pdfName]: true, // Enable download when iframe has loaded
    }));
  };

  const handleDownloadClick = (pdf) => {
    if (canDownload[pdf.name]) {
      // Automatically download the file
      const link = document.createElement('a');
      link.href = pdf.src;
      link.download = `${pdf.name}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Show a simple alert after the download
      alert(`${pdf.name} PDF will be downloaded!`);
    }
  };

  return (
    <div className="access-container">
      <p>Here you can access PDF's of the project.</p>

      
        {pdfs.map((pdf, index) => (
          <div className="pdf-section" key={index}>
            <h2>{pdf.name}</h2>
            <iframe
              src={pdf.src}
              title={`${pdf.name} PDF`}
              onLoad={() => handleIframeLoad(pdf.name)}
              width="100%"
              height="600px"
            />
            <button
              onClick={() => handleDownloadClick(pdf)} // Handle download and alert
              className={!canDownload[pdf.name] ? 'disabled' : ''}
              disabled={!canDownload[pdf.name]} // Disable the button until iframe is fully loaded
            >
              Download {pdf.name} PDF
            </button>
          </div>
        ))}
      </div>
  );
};

export default Access;
