import React from 'react';
import '../OrganicStyles/OrganicStyles.css'

const Alkanols = () => {
  return (
    <div className="organic-page">
      <div className="organic-head">
        <h1>ALKANOLS</h1>
        <hr />
        <p>
          Alkanols, commonly known as alcohols, are organic compounds that contain one or more hydroxyl groups (-OH) attached to a carbon atom. The simplest alkanol is methanol, with the chemical formula CH<sub>3</sub>OH.
        </p>
      </div>
      <div className="organic-content">
        <h2>Overview of Alkanols</h2>
        <p>
          Alkanols are a class of alcohols in which the hydroxyl group is attached to a saturated carbon atom (single bond). Their general formula is C<sub>n</sub>H<sub>2n+1</sub>OH, where n is the number of carbon atoms.
        </p>

        <h2>Properties of Alkanols</h2>
        <p><b>State:</b> Alkanols can exist as liquids or solids at room temperature depending on their chain length.</p>
        <p><b>Melting and Boiling Points:</b> Alkanols have higher melting and boiling points than alkanes and alkenes due to hydrogen bonding between hydroxyl groups.</p>
        <p><b>Solubility:</b> Lower alkanols (e.g., methanol, ethanol) are soluble in water due to hydrogen bonding.</p>

        <h2>Chemical Reactions of Alkanols</h2>
        <p><b>Oxidation:</b> Primary alkanols can be oxidized to aldehydes and then to carboxylic acids; secondary alkanols are oxidized to ketones.</p>
        <p><b>Esterification:</b> Alkanols react with carboxylic acids to form esters and water.</p>
        <p><b>Dehydration:</b> Alkanols can undergo dehydration reactions to form alkenes in the presence of acid.</p>

        <h2>Uses of Alkanols</h2>
        <p><b>Solvents:</b> Alkanols like ethanol and isopropanol are widely used as solvents in pharmaceuticals and cosmetics.</p>
        <p><b>Fuel:</b> Methanol and ethanol are used as alternative fuels in automobiles and industry.</p>
        <p><b>Antiseptics:</b> Alkanols like ethanol are used as disinfectants and antiseptics in medical applications.</p>

        <h2>Characteristics of Alkanols</h2>
        <p>Alkanols contain a hydroxyl group (-OH) attached to a saturated carbon atom, making them polar and able to form hydrogen bonds.</p>
        <p>The hydroxyl group in alkanols is responsible for their higher boiling points and solubility in water.</p>

        <h2>Bond Formation in Alkanols</h2>
        <p>In alkanols, the hydroxyl group (-OH) is attached to a carbon atom through a sigma bond, contributing to the overall polarity of the molecule and its ability to form hydrogen bonds.</p>
      </div>
    </div>
  );
};

export default Alkanols;
