import Isaac from './Isaac.jpg';
import Austin from './Austin.jpg';
import Desmond from './Desmond.jpg';
import Blessing from './Blessing.jpg';

const developers = [
    {
        name: 'Isaac Abakah',
        description: 'Isaac is the visionary behind this project, turning a dream into reality. With a strong foundation in both development and design, he brings creativity and technical prowess to the team. Isaac specializes in front-end development, crafting seamless user experiences with HTML, CSS, and JavaScript. He is also a CodePen enthusiast, where he showcases innovative web designs.',
        link: 'ikaydreams',
        image: Isaac, 
        role: 'FOUNDER & LEAD DESIGNER',
    },
    {
        name: 'Austin Bediako',
        description: 'Austin is a full-stack developer with a passion for building robust and scalable applications. From designing sleek front-end interfaces to engineering complex back-end solutions, Austin excels at bringing ideas to life through code. He’s an active contributor on GitHub and is always exploring new technologies to improve his craft.',
        link: 'kaeytee',
        image: Austin , 
        role: 'CO-FOUNDER & LEAD DEVELOPER',
    },
    {
        name: 'Desmond Yeboah',
        description: 'Desmond is a dynamic leader with a diverse skill set. He oversees project documentation, customer support, and community engagement, ensuring smooth operations across all fronts. As a co-founder, Desmond’s organizational skills and commitment to quality drive the team’s success.',
        link:'big-des752',
        image: Desmond,
        role: 'CO-FOUNDER & OPERATIONS LEAD',
    },
    {
        name: 'Blessing Bosomtwe',
        description: 'Blessing is a medical student at the University of Ghana with a deep love for coding. His expertise in Java and Python has led him to develop cutting-edge algorithms that solve real-world problems. His analytical mindset and problem-solving abilities make him an invaluable asset to the development team.',
        link: 'blessboss',
        image: Blessing,
        role: 'CO-FOUNDER & ALGORITHM SPECIALIST',
    }
];

export default developers;