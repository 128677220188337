// This file is in your Assets folder: Assets/pdfs.js
import Alkanes from './Alkanes.pdf';
import Alkenes from './Alkenes.pdf';
import Alkynes from './Alkynes.pdf';
import Brief from './Organic-Brief.pdf';
import rules from './IUPAC_RULE.pdf';

const pdfs = [
    {
        name: 'Alkanes',
        src: Alkanes,
    },
    {
        name: 'Alkenes',
        src: Alkenes,
    },
    {
        name: 'Alkynes',
        src: Alkynes, // Ensure this path is correct
    },
    {
        name: 'Organic Chemistry Brief',
        src: Brief,
    },
    {
        name: 'IUPAC Nomenclature Rules',
        src: rules
    }
];

export default pdfs;
