import React from 'react';
import '../OrganicStyles/OrganicStyles.css'
const Alkenes = () => {
  return (
    <div className="organic-page">
      <div className="organic-head">
        <h1>ALKENES</h1>
        <hr />
        <p>
          Alkenes, also known as olefins, are unsaturated hydrocarbons that contain one or more carbon-carbon double bonds (C=C). They are generally more reactive than alkanes due to the presence of these double bonds.
        </p>
      </div>
      <div className="organic-content">
        <h2>Overview of Alkenes</h2>
        <p>
          Alkenes are hydrocarbons that contain at least one carbon-carbon double bond. The general molecular formula for alkenes is C<sub>n</sub>H<sub>2n</sub>, where n represents the number of carbon atoms.
        </p>

        <h2>Properties of Alkenes</h2>
        <p><b>State:</b> Alkenes can exist as gases, liquids, or solids at room temperature depending on their chain length.</p>
        <p><b>Melting and Boiling Points:</b> The boiling points of alkenes are generally higher than those of alkanes due to the presence of the polarizable double bond.</p>
        <p><b>Solubility:</b> Alkenes are nonpolar molecules but can show a weak dipole moment due to the double bond.</p>

        <h2>Chemical Reactions of Alkenes</h2>
        <p><b>Hydrogenation:</b> Alkenes can react with hydrogen (H<sub>2</sub>) to form alkanes.</p>
        <p><b>Halogenation:</b> Alkenes can react with halogens (Cl<sub>2</sub>, Br<sub>2</sub>) to form dihaloalkanes.</p>
        <p><b>Hydration:</b> Alkenes can react with water to form alcohols.</p>

        <h2>Uses of Alkenes</h2>
        <p><b>Production of Polymers:</b> Ethylene (C<sub>2</sub>H<sub>4</sub>) is a starting material for many common polymers.</p>
        <p><b>Production of Alcohols:</b> Alkenes can be converted to alcohols via hydration reactions.</p>
        <p><b>Fuel:</b> Alkenes like propene (C<sub>3</sub>H<sub>6</sub>) are used as components in LPG.</p>

        <h2>Characteristics of Alkenes</h2>
        <p>Alkenes contain a carbon-carbon double bond that restricts rotation around the bond, leading to isomerism.</p>
        <p>The carbon atoms in the double bond are sp<sup>2</sup> hybridized, resulting in a planar geometry.</p>

        <h2>Bond Formation in Alkenes</h2>
        <p>In alkenes, the carbon-carbon double bond consists of a sigma bond and a pi bond, which contributes to the reactivity of alkenes.</p>
      </div>
    </div>
  );
};

export default Alkenes;
